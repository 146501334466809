<template>
  <v-row id="group-ranking-container">
    <v-col sm="4" md="4" lg="4" v-if="displayResults" id="group-tabs-col">
      <v-card class="full-height">
        <v-tabs v-model="tab" color="black" fixed-tabs :show-arrows="false">
          <v-tab
            class="px-0"
            v-for="result in individualResults"
            active-class="default-active"
            :key="result.user_id"
            ><v-icon large :color="result.color"> mdi-account </v-icon></v-tab
          >
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="result in individualResults" :key="result.user_id">
            <v-card>
              <v-card-text>
                {{
                  result.user_id === userId
                    ? "Mon classement"
                    : `Classement de `
                }}
                <span v-if="result.user_id !== userId && users.length">
                  {{ users.find((u) => u.id === result.user_id).username }}
                </span>
                <v-simple-table v-if="result.ranking">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(item, index) in result.ranking"
                        :key="item.id"
                      >
                        <td class="py-0 px-1">{{ index + 1 }}</td>
                        <td>{{ getItemLabel(item.item_id) }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
    <v-col>
      <v-card class="full-height">
        <v-card-text class="black--text">
          <v-row>
            <v-col>
              <div class="d-flex flex-row">
                <p class="text-h6 py-0 my-0">Classement de groupe</p>
                <v-btn icon color="green" @click="onRefresh">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
              </div>
              <div class="d-flex flex-row draggable-list">
                <draggable
                  tag="ol"
                  @update="onUpdate"
                  draggable=".item"
                  v-bind="dragOptions"
                  @choose="onChoseHandler"
                >
                  <template v-for="(item, index) in selectedItems">
                    <DroppableItem
                      :id="`${index}-dropzone`"
                      class="item my-1"
                      sourcelist="selected_items"
                      :key="index"
                      v-if="!item.id"
                      :on-drop="(event) => onDrop(index, event)"
                    >
                      <button class="ml-4 placeholder mt-1"></button>
                    </DroppableItem>
                    <li
                      class="item my-2"
                      v-if="item.id"
                      :id="item.id"
                      :key="item.id"
                      sourcelist="selected_items"
                    >
                      <div class="ranked-group-item ml-4 py-1 px-3">
                        {{ item.label }}
                      </div>
                    </li>
                  </template>
                </draggable>
              </div>
            </v-col>
            <v-col v-if="availableItems.length">
              <p class="text-h6 py-0 my-0">Eléments à classer</p>
              <draggable draggable="false" id="items_to_classified">
                <transition-group type="transition" name="flip-list">
                  <DraggableItem
                    v-for="(item, index) in availableItems"
                    :key="index"
                    :transferData="{
                      item,
                      old_index: index,
                      from: 'available_items',
                    }"
                  >
                    <div class="item py-1 px-3" sourcelist="available_items">
                      {{ item.label }}
                    </div>
                  </DraggableItem>
                </transition-group>
              </draggable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import draggable from "vuedraggable";
import { webSocketUrl } from "@/env";
import moveHandlers from "@/utils/moveHandlers";
import clientCache from "@/utils/cacheUtils";
import { MoveType } from "@/schemas/Enums";
import { UFliterals } from "@/utils/literals";
import { getFilledRanking } from "@/store/modules/session/getters";
import DroppableItem from "@/components/task/ranking/components/DroppableItem.vue";
import DraggableItem from "@/components/task/ranking/components/DraggableItem.vue";

export default {
  name: "SlideGroupRankingList",
  components: {
    DraggableItem,
    DroppableItem,
    draggable,
  },
  props: {
    sessionId: { required: true, type: String },
    rankingWS: { required: true, type: WebSocket },
    refreshHandler: { required: true, type: Function },
  },
  data: () => ({
    tab: 0,
    dragOptions: {
      animation: 0,
      group: "ranking",
      disabled: false,
      ghostClass: "ghost",
    },
    lastMove: null,
    results: [],
    displayResults: false,
    heartbeatId: 0,
  }),
  created() {
    this.rankingWS.onmessage = this.receivedMoveHandler;
    const reconnectWS = () => {
      this.rankingWS = new WebSocket(
        `${webSocketUrl}/move/${this.sessionId}/${this.userId}`
      );
    };
    this.rankingWS.onerror = function (error) {
      console.log(`Error with slide ranking websocket`);
      reconnectWS();
    };

    this.$store.dispatch("session/getSessionResults", {
      sessionId: this.sessionId,
      next: (results) => {
        this.results = results;
        this.displayResults = true;
      },
    });
    this.$store.dispatch("session/fetchSessionUsers", {
      sessionId: this.sessionId,
    });
  },
  beforeDestroy() {
    if (this.rankingWS) {
      this.rankingWS.close(1000);
    }
  },
  computed: {
    ...mapState("session", [
      "session",
      "itemsToBeRanked",
      "rankedItems",
      "users",
      "correlationId"
    ]),
    ...mapState("user", ["userId", "username"]),
    items() {
      return this.session.activity.items;
    },
    // Items to be dragged
    availableItems: {
      get() {
        return this.itemsToBeRanked;
      },
      set(toSet) {
        let toDelete, index;
        ({ toDelete, index } = toSet);
        if (toDelete) {
          this.setItemsToBeRanked({ remove: true, index });
        }
      },
    },
    // Dragged items
    selectedItems: {
      get() {
        return Object.values(this.rankedItems);
      },
      set(droppedObject) {
        let item, index, old_index, from;
        ({ item, index, old_index, from } = droppedObject);
        if (from === "selected_items") {
          this.setRankedItems({
            remove: true,
            old_index,
            add: true,
            item,
            index,
          });
        } else {
          this.availableItems = { toDelete: true, index: old_index };
          if (!this.selectedItems[index].id) {
            this.setRankedItems({ add: true, item, index });
          }
        }
      },
    },
    individualResults() {
      if (this.results[0].user_id !== this.userId) {
        const filledRankingResults = getFilledRanking(
          this.$store.state.session,
          this.results
        );
        const userResultsIndex = filledRankingResults.findIndex(
          (res) => res.user_id === this.userId
        );
        if (-1 !== userResultsIndex) {
          const toMove = filledRankingResults[userResultsIndex];
          filledRankingResults.splice(userResultsIndex, 1);
          filledRankingResults.splice(0, 0, toMove);
          return filledRankingResults;
        }
        return this.results;
      }
      return getFilledRanking(this.$store.state.session, this.results);
    },
  },
  methods: {
    ...mapMutations("session", {
      setItemsToBeRanked: "SET_ITEMS_TO_BE_RANKED",
      setRankedItems: "SET_RANKED_ITEMS",
      setCorrelationId: "SET_CORRELATION_ID",
    }),
    getUF(key) {
      return UFliterals[key] || "";
    },
    // sendMoveMessage(move) {
    //   let oldPosition =
    //     move.old_index || move.old_index === 0
    //       ? move.dragged_from !== "items_to_be_ranked"
    //         ? "de la position <strong>" +
    //           parseInt(move.old_index + 1) +
    //           "</strong> "
    //         : ""
    //       : "";
    //   let message = {
    //     session_id: this.sessionId,
    //     author_id: this.userId,
    //     content: `<strong>${this.username}</strong> a déplacé l'élément
    //      <strong>${this.getItemLabel(move.item_id)}</strong>
    //      ${oldPosition}
    //      vers la position <strong>${parseInt(move.new_index + 1)}</strong>.`,
    //     type: "action",
    //   };
    //   this.chatWS.send(JSON.stringify({ message, type: "message" }));
    // },
    onUpdate(event) {
      let move = {
        user_id: this.userId,
        session_id: this.sessionId,
        dragged_from: event.item.attributes.sourcelist.nodeValue,
        dragged_to: event.item.attributes.sourcelist.nodeValue,
        old_index: event.oldIndex,
        new_index: event.newIndex,
        type: MoveType.Moved,
        item_id: event.item.id,
        correlation_id: Number(this.correlationId) + 1,
      };
      if (this.rankingWS && this.rankingWS.readyState === WebSocket.OPEN) {
        this.rankingWS.send(JSON.stringify(move));
        this.setRankedItems({
          whole: true,
          items: moveHandlers.moveItem(
            move,
            this.selectedItems,
            this.items.find((item) => item.id === event.item.id)
          ),
        });
      } else {
        //   FIXME : reconnect websocket
      }

      // this.sendMoveMessage(move);
    },
    onChoseHandler(event) {
      if (event.item.className.includes("dropzone")) {
        // Fix for chromium and chrome
        let dropzone = document.getElementById(event.item.id);
        dropzone.setAttribute("draggable", false);
      }
    },
    onDrop(index, event) {
      let moveData = JSON.parse(event.dataTransfer.getData("value"));
      if (moveData.item.id) {
        let alreadyInList = this.selectedItems.findIndex(
          (item) => item.id === moveData.item.id
        );
        if (alreadyInList === -1) {
          this.selectedItems = {
            ...moveData,
            index,
          };
          let addedMove = {
            user_id: this.userId,
            session_id: this.sessionId,
            dragged_from: moveData.from,
            dragged_to: "selected_items",
            item_id: moveData.item.id,
            new_index: index,
            type: MoveType.Added,
            correlation_id: this.correlationId + 1,
          };
          if (this.rankingWS && this.rankingWS.readyState === WebSocket.OPEN) {
            this.rankingWS.send(JSON.stringify(addedMove));
          } else {
            //   FIXME : reconnect websocket
          }
          // this.sendMoveMessage(addedMove);
          // let removedMove = {
          //   user_id: this.userId,
          //   session_id: this.sessionId,
          //   dragged_from: moveData.from,
          //   dragged_to: "selected_items",
          //   item_id: moveData.item.id,
          //   old_index: moveData.old_index,
          //   type: MoveType.Removed,
          // };
          // this.rankingWS.send(JSON.stringify(removedMove));
        } else {
          this.availableItems = {
            toDelete: true,
            index: moveData.old_index,
          };
          let removedMove = {
            user_id: this.userId,
            session_id: this.sessionId,
            dragged_from: moveData.from,
            dragged_to: "selected_items",
            item_id: moveData.item.id,
            old_index: moveData.old_index,
            type: MoveType.Removed,
          };
          this.rankingWS.send(JSON.stringify(removedMove));
        }
      }
    },
    getItemLabel(itemId) {
      return this.session.activity.items.find((item) => item.id === itemId)
        ?.label;
    },
    receivedMoveHandler(event) {
      let move = JSON.parse(event.data);

      if (move.type === "heartbeat") {
        this.$parent.lastHeartbeatId = move.heartbeat_id;
        return;
      }

      if (move.correlation_id !== undefined) {
        this.setCorrelationId(Number(move.correlation_id));
      }

      if (move.items_to_be_ranked) {
        this.setItemsToBeRanked({
          whole: true,
          items: move.items_to_be_ranked.items,
        });
        if (move.ranked_items) {
          this.setRankedItems({
            whole: true,
            items: move.ranked_items.items,
          });
        }

        return;
      }
      if (this.lastMove) {
        if (move.item_id === this.lastMove.item_id) {
          if (move.dragged_to === this.lastMove.dragged_to) {
            if (move.dragged_from === this.lastMove.dragged_from) {
              if (move.type === this.lastMove.type) {
                if (move.type === "removed") {
                  if (move.old_index === this.lastMove.old_index) {
                    return;
                  }
                }
                if (move.type === "added") {
                  if (move.new_index === this.lastMove.new_index) {
                    return;
                  }
                }
                if (move.type === "moved") {
                  if (move.new_index === this.lastMove.new_index) {
                    if (move.old_index === this.lastMove.old_index) {
                      return;
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.lastMove = move;

      if (move.user_id !== this.userId) {
        let rank = [];

        let draggedFromList;
        let draggedToList;
        let draggedFromCacheKey;
        let draggedToCacheKey;

        if (move.dragged_from === "available_items") {
          draggedFromList = [...this.availableItems];
          draggedToList = [...this.selectedItems];
          draggedFromCacheKey = "items_to_be_ranked";
          draggedToCacheKey = "ranked_items";
        } else {
          draggedFromList = [...this.selectedItems];
          draggedToList = [...this.availableItems];
          draggedFromCacheKey = "ranked_items";
          draggedToCacheKey = "items_to_be_ranked";
        }
        console.log(
          `dragged from ${draggedFromCacheKey} to ${draggedToCacheKey}...`
        );
        if (move.dragged_from === move.dragged_to) {
          let item = this.session.activity.items.find(
            (item) => item.id === move.item_id
          );
          rank = moveHandlers.moveItem(move, draggedFromList, item);
          clientCache.cache(draggedFromCacheKey, JSON.stringify(rank));
          move.dragged_to === "available_items"
            ? this.setItemsToBeRanked({ whole: true, items: rank })
            : this.setRankedItems({ whole: true, items: rank });
        } else {
          if (Object.hasOwn(move, "new_index")) {
            let item = this.items.find((item) => item.id === move.item_id);
            rank = moveHandlers.addItem(move, draggedToList, item);
            clientCache.cache(draggedToCacheKey, JSON.stringify(rank));
            move.dragged_to === "selected_items"
              ? this.setRankedItems({ whole: true, items: rank })
              : this.setItemsToBeRanked({ whole: true, items: rank });
          } else {
            rank = moveHandlers.removeItem(move, draggedFromList);
            clientCache.cache(draggedFromCacheKey, JSON.stringify(rank));
            move.dragged_from === "available_items"
              ? this.setItemsToBeRanked({ whole: true, items: rank })
              : this.setRankedItems({ whole: true, items: rank });
          }
        }
      }
    },
    onRefresh() {
      console.log("Page refreshed");
      console.log(this.receivedMoveHandler)
      // See : https://www.rfc-editor.org/rfc/rfc6455.html#section-7.1.5
      this.refreshHandler(this.receivedMoveHandler);
    },
  },
};
</script>

<style scoped>
.default-active {
  background-color: #eeeeee;
}
.ghost {
  opacity: 1;
}
.ghost .ranked-group-item {
  opacity: 1;
  background: #263238;
  color: #eceff1;
}

li::before {
  content: counter(li);
  display: inline-block;
  flex-basis: 8%;
  text-align: center;
}

li {
  counter-increment: li;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  max-width: 100%;
}

ol {
  padding: 0;
  width: 100%;
}

.ranked-group-item {
  width: 100%;
  cursor: move;
  flex-basis: 92%;
  height: fit-content;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
  background-color: white;
  border-radius: 4px;
  font-weight: 500;
  text-align: center;
  outline: 0;
  position: relative;
  text-indent: 0.0892857143em;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  border: thin solid currentColor;
}

.placeholder {
  width: 100%;
  height: 30px;
  border-radius: 4px;
  display: block;
  align-items: center;
  justify-content: center;
  cursor: move;
  flex-basis: 92%;
  min-height: 30px;
  position: relative;
  transition-duration: 0.28s;
  transition-property: box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  user-select: none;
  background-color: #cfd8dc;
}

.full-height {
  height: 100%;
}

.draggable-list ol {
  padding-left: unset;
}

.v-slide-group__prev {
  display: none;
  min-width: unset;
}
.v-slide-group__content {
  width: 100%;
}

.v-tab {
  min-width: unset !important;
}
</style>

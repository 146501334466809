
import { Vue, Component, Prop } from "vue-property-decorator";
import { UserActions } from "@/schemas/Enums";
import { webSocketUrl } from "@/env";
import { mapActions, mapMutations, mapState } from "vuex";
import clientCache from "@/utils/cacheUtils";
import { IUser } from "@/schemas/IUser";
import WebSocketManager from "@/utils/websocketManager";

@Component({
  name: "AgreementSubmitButton",
  computed: {
    ...mapState("user", ["userId"]),
    ...mapState("session", ["users"]),
  },
  methods: {
    ...mapMutations({ setSnack: "SET_SNACK" }),
    ...mapActions("session", ["getUserWhoAgreedOnSessionEnd"]),
  },
})
export default class AgreementSubmitButton extends Vue {
  @Prop({ required: true, type: String })
  sessionId!: string;

  endSessionWS: WebSocketManager | null = null;
  requested = false;
  agreedUserIds = [];
  agreedUsers: IUser[] = [];

  created(): void {
    console.log("Creation du WS qu'on veut récup"),
    this.endSessionWS = new WebSocketManager(
      `${webSocketUrl}/session/end/${this.sessionId}/${this.userId}`,
      this.endSessionWSMessageHandler,
      this.onRetryFail,
      () => console.log("endSessionWS error handler")
    );
    console.log(this.endSessionWS);
  }



  beforeDestroy(): void {
    if (this.endSessionWS) {
      this.endSessionWS.close(
        1000,
        "Normal socket shutwdown due to component's beforeDestroy hook."
      );
    }
  }

  get hasAgreed(): boolean {
    return this.agreedUserIds.indexOf(this.userId) !== -1;
  }

  requestPlayerAgreementToEndSession(): void {
    this.endSessionWS.send(
      {
        action: UserActions.Request,
        user_id: this.userId,
      },
      () => console.log("send error for endSessionWS"),
      () => {
        window.localStorage.setItem("end_requested", true);
        window.localStorage.setItem("agreed", true);
        this.requested = true;
      }
    );
  }

  acceptGroupSessionEnd(): void {
    this.endSessionWS.send(
      {
        action: UserActions.Agreed,
        user_id: this.userId,
      },
      () => console.log("send error for endSessionWS"),
      () => {
        window.localStorage.setItem("agreed", true);
      }
    );
  }

  denyGroupSessionEnd(): void {
    this.endSessionWS.send(
      {
        action: UserActions.Denied,
        user_id: this.userId,
      },
      () => console.log("send error for endSessionWS"),
      () => {
        window.localStorage.setItem("end_requested", false);
        window.localStorage.setItem("agreed", false);
        this.requested = false;
      }
    );
  }

  endSessionWSMessageHandler(action: any): void {
    if (action.status === UserActions.Request) {
      this.getUserWhoAgreedOnSessionEnd({
        sessionId: this.sessionId,
        next: (agreedUsersIds: string[]) => {
          this.agreedUserIds = agreedUsersIds;
          if (agreedUsersIds.length) {
            this.requested = true;
            this.agreedUsers = this.users.filter((user: IUser) =>
              agreedUsersIds.includes(user.id)
            );
          } else {
            this.agreedUsersIds = [];
            this.agreedUsers = [];
            clientCache.cache("end_requested", false);
            clientCache.cache("agreed", false);
          }
        },
      });
      window.localStorage.setItem("end_requested", true);
      if (this.agreedUserIds.indexOf(action.user_id) === -1) {
        this.agreedUserIds.push(action.user_id);
      }
      if (
        this.agreedUsers.findIndex((user) => user.id === action.user_id) === -1
      ) {
        this.agreedUsers.push(
          this.users.find((user) => user.id === action.user_id)
        );
      }
    }
    if (action.status === UserActions.Denied) {
      window.localStorage.setItem("end_requested", false);
      this.requested = false;
      this.agreedUsers = [];
      this.agreedUserIds = [];
      if (action.user_id === this.userId) {
        this.setSnack({
          snackColor: "info",
          snackText: `Vous avez demandé à continuer la session.`,
        });
      } else {
        this.setSnack({
          snackColor: "info",
          snackText: `${
            this.users.find((user) => user.id === action.user_id).username
          } souhaite continuer la session.`,
        });
      }
    }
    if (action.status === UserActions.Agreed) {
      this.agreedUserIds.push(action.user_id);
      this.agreedUsers = this.users.filter((user) =>
        this.agreedUserIds.includes(user.id)
      );
    }
    if (action.status === UserActions.Ended) {
      this.$emit("agreed");
      this.requested = false;
    }
  }

  onRetryFail(event: CloseEvent): void {
    // Alert user and provide a user-friendly message
    this.setSnack({
      snackText:
        "La connexion WebSocket pour la fin de la tache a échoué après 3 tentatives. Veuillez vérifier votre connexion réseau.",
      snackColor: "error",
      snackClosable: true,
    });
  }
}
